import React, { Component } from 'react'
import Layout from './Layout'
import SEO from './SEO'
import TopSection from './TopSection'
import VideoSection from './VideoSection'
import FooterSection from './FooterSection'
import { TranslationConsumer } from './Translation'

const MainSite = props => {
  return (
    <Layout language={props.language}>
      <TranslationConsumer>
        {({ t }) => (
          <SEO
            lang={props.language}
            title={t('site_title')}
            keywords={[
              `David Bowie`,
              `AR`,
              `augmented reality`,
              `David Bowie is`,
            ]}
          />
        )}
      </TranslationConsumer>

      <TopSection />
      <VideoSection />
      <FooterSection />
    </Layout>
  )
}

export default MainSite
