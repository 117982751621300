import React from 'react'
import Section from './Section'

import './TopSection.css'
import logo from '../images/logo.svg'
import Slideshow from './Slideshow'
import { TranslationConsumer } from './Translation'

const TopSection = () => {
  return (
    <TranslationConsumer>
      {({ t }) => (
        <Section className="TopSection">
          <div className="TopSection_content">
            <div className="TopSection_main-content">
              <div className="TopSection_logo-container">
                <img src={logo} />
              </div>
              <div className="TopSection_main-text-container">
                <div className="TopSection_main-text-title">
                  {t('top_title')}
                </div>
                <div className="TopSection_main-text-text">
                  {t('top_col1_content')}
                </div>
              </div>
            </div>
            <div className="TopSection_secondary-content">
              <div className="TopSection_secondary-content-title">
                {t('top_col2_content')}
              </div>
            </div>
          </div>

          <div className="TopSection_slideshow">
            <Slideshow />
          </div>
        </Section>
      )}
    </TranslationConsumer>
  )
}

export default TopSection
