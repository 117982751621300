import React, { Component } from 'react'
import Slider from 'react-slick'

import './Slideshow.css'
import phoneTemplate from '../images/PhoneTemplate.png'
import slide1 from '../images/slideshow/1.png'
import slide2 from '../images/slideshow/2.png'
import slide3 from '../images/slideshow/3.png'
import slide4 from '../images/slideshow/4.png'
import slide5 from '../images/slideshow/5.png'
import slide6 from '../images/slideshow/6.png'
import slide7 from '../images/slideshow/7.png'
import forwardArrow from '../images/ForwardArrow.png'
import backArrow from '../images/BackArrow.png'

class Slideshow extends Component {
  state = {
    width: 0,
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSize)
    this.setSize()
  }

  setSize = () => {
    const height = this.refs.imageContainer.getBoundingClientRect().height
    this.setState({
      width: height / 2,
    })
  }

  render() {
    return (
      <div className="Slideshow">
        <div className="Slideshow_overlay">
          <div
            className="Slideshow_overlay-image-container"
            style={{ width: this.state.width }}
            ref="imageContainer"
          >
            <img className="Slideshow_overlay-image" src={phoneTemplate} />
            <div className="Slideshow_slides">
              <Slider
                ref="slider"
                arrows={false}
                autoplay={true}
                adaptiveHeight={true}
              >
                <div className="Slideshow_slide">
                  <img src={slide1} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide2} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide3} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide4} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide5} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide6} />
                </div>
                <div className="Slideshow_slide">
                  <img src={slide7} />
                </div>
              </Slider>
            </div>

            <a
              className="Slideshow_arrow Slideshow_back-arrow"
              onClick={this.handleClickBack}
            >
              <img src={backArrow} />
            </a>
            <a
              className="Slideshow_arrow Slideshow_forward-arrow"
              onClick={this.handleClickForward}
            >
              <img src={forwardArrow} />
            </a>
          </div>
        </div>
      </div>
    )
  }

  handleClickForward = () => {
    this.refs.slider.slickNext()
  }

  handleClickBack = () => {
    this.refs.slider.slickPrev()
  }
}

export default Slideshow
