import React, { Component } from 'react'
import { withPrefix } from 'gatsby'
import Section from './Section'
import { findDOMNode } from 'react-dom'

import './VideoSection.css'
import videoPlayButton from '../images/VideoPlayButton.png'

const videoAspect = 16 / 9

class VideoSection extends Component {
  state = {
    width: 0,
    height: 0,
    offsetX: 0,
    offsetY: 0,
    showPlayButton: true,
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSize)
    this.setSize()

    this.refs.video.addEventListener('play', () => {
      this.setState({
        showPlayButton: false,
      })
    })
  }

  setSize = () => {
    const { width, height } = findDOMNode(this).getBoundingClientRect()

    let videoWidth = width
    let videoHeight = width / videoAspect

    if (videoHeight < height) videoHeight = height
    videoWidth = videoHeight * videoAspect

    const offsetX = -(videoWidth - width) / 2
    const offsetY = -(videoHeight - height) / 2

    this.setState({
      width: videoWidth,
      height: videoHeight,
      offsetX,
      offsetY,
    })
  }

  render() {
    return (
      <Section className="VideoSection">
        <video
          ref="video"
          style={{
            position: 'absolute',
            width: this.state.width,
            height: this.state.height,
            left: this.state.offsetX,
            top: this.state.offsetY,
          }}
          autoPlay={true}
          muted={true}
          loop={true}
          src={withPrefix('BowieSplash.mp4')}
        />

        {this.state.showPlayButton && (
          <a
            className="VideoSection_play-button"
            onClick={this.handlePlayPressed}
          >
            <img src={videoPlayButton} />
          </a>
        )}
      </Section>
    )
  }

  handlePlayPressed = () => {
    this.refs.video.play()
  }
}

export default VideoSection
